h1,h2,h3,p,span {

    transition: all 0.5s ease-in-out;

    &.roboto-light {
        font-family: 'Roboto-Light', sans-serif;
        font-weight: 200;
    }  
    &.roboto-medium {
        font-family: 'Roboto-Medium', sans-serif;
        font-weight: 300;
    }
    &.roboto-bold {
        font-family: 'Roboto-Bold', sans-serif;
        font-weight: 600;
    }
    &.poppins-extralight {
        font-family: 'Poppins-ExtraLight', sans-serif;
        font-weight: 100;
    }
    &.poppins-light {
        font-family: 'Poppins-Light', sans-serif;
        font-weight: 200;
    }
    &.poppins-regular {
        font-family: 'Poppins-Regular', sans-serif;
        font-weight: 300;
    }    
    &.poppins-medium {
        font-family: 'Poppins-Medium', sans-serif;
        font-weight: 400;
    }
    &.poppins-semibold {
        font-family: 'Poppins-SemiBold', sans-serif;
        font-weight: 500;
    }    
    &.poppins-bold {
        font-family: 'Poppins-bold', sans-serif;
        font-weight: 600;
    }   
    &.white {
        color:#FFFFFF;
    }
    &.lightGrey {
        color: #818181;
    }
    &.grey {
        color: #4C4C4C;
    }
    &.yellow {
        color: #987700;
    }
    &.darkGrey {
        color: #1E1E1E;
    }
    &.black {
        color: #000000;
    }
    &.font-200 {
        font-size: calc(80px + (200 - 80) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(120px + (240 - 120) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-80 {
        font-size: calc(36px + (80 - 36) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(42px + (96 - 42) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-71 {
        font-size: calc(36px + (71 - 36) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(42px + (90 - 42) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-66 {
        font-size: calc(50px + (66 - 50) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(52px + (80 - 52) * ((100vw - 320px) / (1920 - 320)));

        @media screen and (max-width: 520px) {
            font-size: 40px;
            line-height: 42px;
        }
    }
    &.font-52 {
        font-size: calc(32px + (52 - 32) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(48px + (68 - 48) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-40 {
        font-size: calc(22px + (40 - 22) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(32px + (52 - 32) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-36 {
        font-size: calc(20px + (36 - 20) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(32px + (46 - 32) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-32 {
        font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-30 {
        font-size: calc(18px + (30 - 18) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(24px + (40 - 24) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-28 {
        font-size: calc(22px + (28 - 22) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(24px + (36 - 24) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-24 {
        font-size: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(24px + (32 - 24) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-22 {
        font-size: calc(16px + (22 - 16) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(20px + (32 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-20 {
        font-size: 20px;
        line-height: 32px;
    }
    &.font-18 {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        line-height: calc(20px + (24 - 20) * ((100vw - 320px) / (1920 - 320)));
    }
    &.font-16 {
        font-size: 16px;
        line-height: 24px;
    }
    &.font-13 {
        font-size: 13px;
        line-height: 16px;
    }
    &.font-12 {
        font-size: 12px;
        line-height: 16px;
    }
}

.maxLines-1 {
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: vertical;
}
.maxLines-2 {
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: vertical;
}
.maxLines-3 {
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: vertical;
}
.maxLines-4 {
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: vertical;
}
.maxLines-5 {
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-box-direction: vertical;
}