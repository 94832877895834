footer {
    background-color: #232323;

    .scrollTop {
        position: fixed;
        bottom: 30px;
        right: 30px;
        z-index: 10000;
        transform: rotate(-90deg);
        background-color: transparent;
        transition: all 0.3s ease-in-out;

        button {
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        }

        &:hover button{
            background-color: #232323;
        }

        &.hide {
           opacity: 0;
           pointer-events: none;
        }

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        @media screen and (max-width: 520px) {
            bottom: 20px;
            right: 10px;
        }
    }

    .topRow {
        padding: 40px 0;
        border-bottom: 1px solid #4C4C4C;
        
        > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        img {
            width: 95px;
        }
    }

    .bottomRow {
        padding: 40px 0;

        > div {
            display: flex;
            align-items: flex-start;

            @media screen and (max-width: 991px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }

        > div > div:first-child {
            max-width: 40%;
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            @media screen and (max-width: 991px) {
                max-width: 100%;
            }

            @media screen and (max-width: 680px) {
               flex-direction: column;
               align-items: center;
            }

            a:hover span {                    
                color: #987700;                
            }
        }

        .links-container {
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 680px) {
                align-items: center;
                margin-bottom: 40px
            }
        }

        .socialmedia-container {
            margin-left: 40px;
            display: flex;
            align-items: center;

            @media screen and (max-width: 680px) {
                margin-left: 0;
            }

            a {
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.3s ease-in-out;

                &:not(:first-child) {
                    margin-left: 8px;

                    @media screen and (max-width: 680px) {
                        margin-left: 0;
                    }
                } 
                
                svg {
                    width: 50px;

                    .st1 {
                        stroke: none;
                    }
    
                    .st0,.st1 {
                        transition: 0.3s ease-in-out;
                    }
                }

                &:hover {
                    svg .st0 { 
                        stroke:#FFFFFF;
                    }
                    svg .st1{
                        fill:#FFFFFF;
                    }
                }
            }

            
        }
    }


    .images {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        max-width: calc( 60% - 40px);
        padding-left: 40px;
        width: 100%;

        @media screen and (max-width: 991px) {
            margin-top: 60px;
            justify-content: space-around;
            max-width: 100%;
            padding-left: 0;
        }

        img {
            width: 100%;
            margin-bottom: 24px;
            max-width: 450px;
        }

    }
}