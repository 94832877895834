.checklist {

    h3 {
        margin-bottom: 40px;
    }

    .list-container {

        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 40px;

        > div > div {
            margin-bottom: 24px;
        }
        
        @media screen and (max-width: 911px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 0;
        }
        
    }
   
}