.textButtons {

    > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h2, p {
            text-align: center;
        }

        h2 {
            max-width: 700px;
        }

        p {
            margin-top: 24px;
            max-width: 700px;
        }

        .buttons-container {
            display: flex;
            align-items: center;
            margin-top: 32px;
        }
    }


   
}