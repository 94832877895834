.caseStudyBanner {
    position: relative;
    
    .banner {
        min-height: 100vh;
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .darkerBackground {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(30, 30, 30, 0.7);
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
        }

        .text-container {
            max-width: 900px;
            height: 100%;

            @media screen and (max-height: 800px) and (min-width: 700px) {
                h1 {
                    font-size: 50px;
                    line-height: 60px;
                }
                h2 {
                    font-size: 48px;
                    line-height: 50px
                }
            }
        }

        .category {
            display: block;
            padding: 8px 24px;
            border-radius: 40px;
            border: 1px solid #FFFFFF;
            margin-bottom: 16px;
            margin-top: 8px;
            width: fit-content;
            margin-top: 120px;
        }
    }

    .info-container {
        background-color: #1E1E1E;
        width: 100%;
        

        & > div {
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 1fr));
            gap: 20px;
            
            @media screen and (max-width: 1320px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media screen and (max-width: 767px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
            }
        }

        .statisticsBox {
            background-color: transparent;
            margin: 16px;
        }
    }
}