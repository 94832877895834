.section404 {
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            margin-top: 40px;
            max-width: 400px;
            text-align: center;
        }

        button {
            margin-top: 40px;
        }
    }
}