.checkitem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    img {
        width: 27px;
        margin-right: 12px;
        margin-top: 2px
    }
}