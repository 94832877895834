.partners {

    .sectionTitle-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .partners-list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;  
        
        img {
            margin-bottom: 20px;

            @media screen and (max-width: 767px) {
                max-width: 140px;
            }
        }
    }
}