.categoriesList {
    background-color: #1E1E1E;


    .complexList {
        
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        a {
            display: block;

            &:nth-child(3n+1), &:nth-child(3n+2) {
                max-width: calc((100% / 2) - 20px);
                overflow: hidden;

                @media screen and (max-width: 1024px) {
                    max-width: 100%;
                }
            }

            &:nth-child(3n+1) {
                margin-right: 40px;

                @media screen and (max-width: 1024px) {
                    margin-right: 0;
                }
            }

            &:nth-child(3n+2) {
                margin-top: 200px;

                @media screen and (max-width: 1200px) {
                    margin-top: 160px;
                }

                @media screen and (max-width: 1024px) {
                    margin-top: 80px;
                }

            }
        }

        .item {
            width: 100%;
            margin-top: 80px;
            cursor: pointer;
            position: relative;

            @media screen and (max-width: 1024px) {
                &:first-child {
                    margin-top: 0;
                }
            }

            .colorHover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 0.4s ease-in-out;
            }

            &:hover {
                .colorHover {
                    background-color: rgba(30, 30, 30,0.6);
                }
            }

            .image-container {
                position: relative;
                height: 600px;
                width: 100%;                
                overflow: hidden;
                border-radius: 16px;

                @media screen and (max-width: 1200px) {
                    height: 500px;
                }

                @media screen and (max-width: 680px) {
                    height: 300px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 102%;
                }
            }

            h3 {
                margin-top: 40px
            }

            span {
                margin-top: 16px;
            }

            .bottom-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: -webkit-fill-available;
                padding: 30px;

                @media screen and (max-width: 680px) {
                    padding: 16px
                }
                
                .category-container {
                    border-radius: 100px;
                    border: 2px solid #FFFFFF;
                    padding: 16px 48px;
                    margin-right: 20px;                    
                    width: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;

                    @media screen and (max-width:1365px) {
                        padding: 16px 32px;
                    }

                    @media screen and (max-width: 680px) {
                        padding: 16px 24px;
                    }


                    span {
                        margin: 0;
                        text-align: center;

                        @media screen and (max-width:1365px) {
                            font-size: 20px;
                        }
                    }
                }

                button {
                    margin-top: 12px;
                    width: 68px;
                    height: 68px;

                    @media screen and (max-width: 680px) {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
        }
    }

    .simpleList {
         
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .item {
            margin-top: 80px;
            width: 100%;
            position: relative;

            @media screen and (max-width: 1024px) {
                &:first-child {
                    margin-top: 0;
                }
            }

            
            .colorHover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 0.4s ease-in-out;
            }

            &:hover {
                .colorHover {
                    background-color: rgba(30, 30, 30,0.6);
                }
            }

            .image-container {
                position: relative;
                height: 600px;
                width: 100%;                
                overflow: hidden;
                border-radius: 16px;

                @media screen and (max-width: 1200px) {
                    height: 500px;
                }

                @media screen and (max-width: 680px) {
                    height: 300px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 102%;
                }
            }

            h3 {
                margin-top: 40px
            }

            span {
                margin-top: 16px;
            }

            .bottom-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: -webkit-fill-available;
                padding: 30px;

                @media screen and (max-width: 680px) {
                    padding: 16px
                }
                
                .category-container {
                    border-radius: 100px;
                    border: 2px solid #FFFFFF;
                    padding: 16px 48px;
                    margin-right: 20px;                    
                    width: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;

                    @media screen and (max-width:1365px) {
                        padding: 16px 32px;
                    }

                    @media screen and (max-width: 680px) {
                        padding: 16px 24px;
                    }


                    span {
                        margin: 0;
                        text-align: center;

                        @media screen and (max-width:1365px) {
                            font-size: 20px;
                        }
                    }
                }

                button {
                    margin-top: 12px;
                    width: 68px;
                    height: 68px;

                    @media screen and (max-width: 680px) {
                        height: 50px;
                        width: 50px;
                    }
                }
            }
        }

        .button-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 30px 0;
        }
    }

    .seeMoreArea {
        display: flex;
        width: 100%;

        .options {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            width: 100%;
            gap: 20px;

            @media screen and (max-width: 1365px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media screen and (max-width: 991px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 0;
            }
        }

        .item {
            width: 100%;
            position: relative;

            .image-container {
                position: relative;
                height: 400px;
                width: 100%;                
                overflow: hidden;
                border-radius: 16px;

                    
                .colorHover {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: transparent;
                    transition: all 0.4s ease-in-out;
                }

                &:hover {
                    .colorHover {
                        background-color: rgba(30, 30, 30,0.6);
                    }
                }

                @media screen and (max-width: 1200px) {
                    height: 300px;
                }

                @media screen and (max-width: 680px) {
                    height: 200px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 102%;
                }
            }

            h3 {
                margin-top: 20px
            }

            span {
                margin-top: 6px;
            }

            .bottom-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: -webkit-fill-available;
                padding: 30px;

                @media screen and (max-width: 680px) {
                    padding: 16px
                }
                
                .category-container {
                    border-radius: 100px;
                    border: 2px solid #FFFFFF;
                    padding: 6px 16px;
                    margin-right: 20px;                    
                    width: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;

                    span {
                        margin: 0;
                        text-align: center;

                        @media screen and (max-width:1365px) {
                            font-size: 20px;
                        }
                    }
                }

                button {
                    margin-top: 12px;
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    &.bg-white {
        background-color: #FFFFFF;

        .item > h3, .item > span {
            color: #000000
        }
    }
}