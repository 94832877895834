#menu {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
    background-color: rgba(30, 30, 30, 0.95);
    position: absolute;
    left: 100vw;
    transition: all 0.5s ease-in-out;
    top: 0;
    z-index: 20;

    &.open {
        left: 0;
    }

    .content {
        width: 100%;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        overflow: auto;
        height: 100%;
        max-height: calc(100vh - 320px);
        margin-top: 120px;
        margin-bottom: 200px;
        padding-bottom: 60px; 

        /* width */
        &::-webkit-scrollbar {
            width: 4px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #987700;
        }
    

        h3 {
            cursor: pointer;
            margin-top: 24px;
        }

        a {
            margin-top: 12px;
        }
    }

    .option-menu-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;     
    }

    .dropdown-menu-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

        &.open {
            max-height: 800px;
            transition: max-height 1s ease-in-out;
        }
    }
}