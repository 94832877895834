.accordion-box {
    padding: 32px 40px;
    background-color: #303030;
    border-radius: 12px;
    margin-top: 12px;
    

    &:not(.simple) {
        cursor: pointer;
    }

    @media screen and (max-width: 680px) {
        padding: 24px 32px;
    }

    .title-container {
        position: relative;
        
        h3 {
            max-width: calc(100% - 60px);
        }

        &.full h3 {
            max-width: 100%;
        }
    }

    .description-container {
        max-height: 1000px;
        transition: max-height 1s ease-in-out;
        overflow: hidden;

        &::before {
            content: "";
            display: block;
            height: 24px;
        }

        p {
            max-width: 760px;
        }
    }

    .button {
        width: 40px;
        height: 40px;
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0px;
        transition: all 0.5s ease;
        transform: rotate(180deg);
    }


    &.closed {
        .button {
            transform: rotate(0deg);
        }
        .title-container {
            h3 {
                color: #FFFFFF;
            }
        }
        .description-container {
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        }
    }

}