.caseStudyBody {

    .text-container {
        width: 100%;
        position: relative;
        background-color: #FFFFFF;
       

        .block {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;

            .title, .text {
                margin-bottom: 40px;
            }

            .images-container {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                margin: 120px 0;

                @media screen and (max-width: 1366px) {
                    margin: 60px 0;
                }

                @media screen and (max-width: 1023px) {
                    margin: 30px 0 60px;

                }

                img {
                    border-radius: 16px;
                    height: 620px;

                    @media screen and (max-width: 1365px) {
                        height: 400px;
                    }
                    @media screen and (max-width: 911px) {
                        height: 300px;
                    }
                }

                &.single {
                    img {
                        width: 100%;
                    }
                }

                &.double {
                    img {
                        width: calc(50% - 24px);

                        &:first-child {
                            margin-right: 12px;
                        }

                        &:nth-child(2) {
                            margin-top: 80px;
                            margin-left: 12px;
                        }

                        @media screen and (max-width: 1023px) {
                            width: 100%;

                            &:first-child {
                                margin-right: 0;
                            }
    
                            &:nth-child(2) {
                                margin-top: 30px;;
                                margin-left: 0;
                            }
                        }
                    }
                }

                
            }
        }

        .block:first-child > div:first-child {
            background-color: #E7E7E7;
            position: absolute;
            top: 0;
            width: 100%;
            height: 80%;

            @media screen and (max-width: 1366px) {
                height: 90%;
            }

        }

    }

    .seeMoreArea {
        background-color: #E7E7E7;
        display: flex;
        width: 100%;

        h1 {
            margin-bottom: 60px;
        }

        .options {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            width: 100%;
            gap: 20px;

            @media screen and (max-width: 1365px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            @media screen and (max-width: 991px) {
                grid-template-columns: repeat(1, minmax(0, 1fr));
                gap: 0;
            }
        }

        .item {
            width: 100%;
            margin-bottom: 40px;

            .colorHover {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 0.4s ease-in-out;
            }

            &:hover {
                .colorHover {
                    background-color: rgba(30, 30, 30,0.6);
                }
            }

            .image-container {
                position: relative;
                height: 400px;
                width: 100%;                
                overflow: hidden;
                border-radius: 16px;

                @media screen and (max-width: 1200px) {
                    height: 300px;
                }

                @media screen and (max-width: 680px) {
                    height: 200px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 102%;
                }
            }

            h3 {
                margin-top: 20px
            }

            span {
                margin-top: 6px;
            }

            .bottom-content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                bottom: 0;
                width: -webkit-fill-available;
                padding: 30px;

                @media screen and (max-width: 680px) {
                    padding: 16px
                }
                
                .category-container {
                    border-radius: 100px;
                    border: 2px solid #FFFFFF;
                    padding: 6px 16px;
                    margin-right: 20px;                    
                    width: fit-content;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;

                    span {
                        margin: 0;
                        text-align: center;

                        @media screen and (max-width:1365px) {
                            font-size: 20px;
                        }
                    }
                }

                button {
                    margin-top: 12px;
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .button-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 60px 0;
        }
    }

}