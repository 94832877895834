button {
    padding: 16px 32px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    font-family: 'Roboto-Medium', sans-serif;
    font-weight: 400;
    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (1920 - 320)));
    line-height: 16px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    min-width: fit-content;

    &.poppins {
        font-family: "Poppins-Regular", sans-serif;
        font-weight: 300;
    }

    &.arrowRight {
        background-color: transparent;
        border-radius: 50%;
        padding: 0;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &.bold {
        font-family: 'Roboto-Bold', sans-serif;
        font-weight: 600;
    }

    &.textWhite {
        color: #FFFFFF;
    }

    &.textYellow {
        color: #987700;
    }

    &.textBlack {
        color: #000000;
    }

    &.fullColored {
        
        &.bgYellow {
            background-color: #987700;

            &:hover {
                background-color: #FFFFFF;
                color: #987700;
            }
        }

        &.bgDark {
            background-color: #1E1E1E;

            &:hover {
                background-color: #FFFFFF;
                color: #987700;

               
            }
        }
    }

    &.lineColored {

        &.bgYellow {
            border: 2px solid #987700;
            background-color: transparent;

            &:hover {
                background-color: #987700;
                color: #FFFFFF;
                border: 2px solid #987700;
            }
        }

        &.bgBlack {
            border: 2px solid #000000;
            background-color: transparent;

            &:hover {
                background-color: #000000;
                color: #FFFFFF;
                border: 2px solid #000000;
            }
        }
    }

    &.ml16 {
        margin-left: 16px;
    }

    &.mr16 {
        margin-right: 16px;
    }

    &.rounded {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        img {
            width: 11px;
            margin-left: 2px;
        }
    }
}