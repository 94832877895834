.listTextImage {

    .item:not(.darkMode) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 70px;
        justify-content: space-between;

        @media screen and (max-width: 990px) {
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
        }

        .text-container {
            max-width: 45%;
            margin-top:45px;

            p {
                margin-top: 40px;
            }

            button {
                margin-top: 36px;
            }

            @media screen and (max-width: 990px) {
                max-width: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                flex-direction: column;

                h2, p {
                    text-align: center;
                }
                
            }

            @media screen and (max-width: 520px) {

                p {
                    margin-top: 20px;
                }
                
            }
        }

        .image-container {
            max-width: 55%;
            position: relative;

            @media screen and (max-width: 990px) {
                max-width: 100%;
                width: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: center;
            }
            

            .image-bg {
                background-color: #E9E4CD;
                border-radius: 16px;
                position: absolute;                
            }
            .image {
                width: 100%;
                overflow: hidden;
                border-radius: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    
                }
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            @media screen and (max-width: 990px) {
               flex-direction: column-reverse;
            }
        }

        &:nth-child(4n+1) {
            .image-container {
                margin-left: 40px;

                .image-bg {  
                    width: 450px;
                    height: 561px;
                    top: 0;
                    left: 0;
                }
                .image {
                    width: 450px;
                    height: 561px;
                    margin-left: 60px;
                    margin-top: 50px;
                }

                @media screen and (max-width: 1550px) {
                    .image-bg, .image {
                        width: 400px;
                        height: 500px;
                    }
                    .image {
                        margin-left: 40px;
                        margin-top: 40px;
                    }
                }

                @media screen and (max-width: 1200px) {
                    .image-bg, .image {
                        width: 350px;
                        height: 420px;
                    }
                    .image {
                        margin-left: 40px;
                        margin-top: 40px;
                    }
                }

                @media screen and (max-width: 990px) {
                    margin-left: 0;

                    .image-bg {  
                        margin: auto;
                        left: -60px;
                        right: 0;
                    }
                    .image-bg, .image {
                        width: 500px;
                        height: 320px;
                    }
                    .image {
                        margin-bottom: 0;
                        margin-top: 40px;
                        margin-left: 20px;
                        margin-right: 0;
                    }
                }

                @media screen and (max-width: 680px) {
                    .image-bg, .image {
                        width: 360px;
                        height: 230px;
                        border-radius: 8px;
                    }
                }
                @media screen and (max-width: 520px) {
                    .image-bg {  
                        left: -20px;
                        top: 20px;
                    }
                    .image-bg, .image {
                        width: 260px;
                        height: 180px;
                    }
                }
            }
        }

        &:nth-child(4n+2) {
            .image-container {
                margin-right: 40px;
                
                .image-bg {  
                    width: 561px;
                    height: 446px;
                    top: 0;
                    right: 0;
                }
                .image {
                    width: 561px;
                    height: 446px;
                    margin-right: 60px;
                    margin-top: 50px;
                }

                @media screen and (max-width: 1550px) {
                    .image-bg, .image {
                        width: 500px;
                        height: 400px;
                    }
                    .image {
                        margin-right: 40px;
                        margin-top: 40px;
                    }
                }

                @media screen and (max-width: 1200px) {
                    .image-bg, .image {
                        width: 420px;
                        height: 350px;
                    }
                    .image {
                        margin-right: 40px;
                        margin-top: 40px;
                    }
                }

                @media screen and (max-width: 990px) {
                    .image-bg {  
                        margin: auto;
                        left: -60px;
                        right: 0;
                    }
                    .image-bg, .image {
                        width: 500px;
                        height: 320px;
                    }
                    .image {
                        margin-bottom: 0;
                        margin-top: 40px;
                        margin-left: 20px;
                        margin-right: 0;
                    }
                }
                @media screen and (max-width: 680px) {
                    .image-bg, .image {
                        width: 360px;
                        height: 230px;
                        border-radius: 8px;
                    }
                }
                @media screen and (max-width: 520px) {
                    .image-bg {  
                        left: -20px;
                        top: 20px;
                    }
                    .image-bg, .image {
                        width: 260px;
                        height: 180px;
                    }
                }
            }
        }

        &:nth-child(4n+3) {
            .image-container {
                margin-left: 40px;
                
                .image-bg {  
                    width: 561px;
                    height: 446px;
                    top: 0;
                    right: 0;
                }
                .image {
                    width: 561px;
                    height: 446px;
                    margin-right: 60px;
                    margin-top: 50px;
                }

                @media screen and (max-width: 1550px) {
                    .image-bg, .image {
                        width: 500px;
                        height: 400px;
                    }
                    .image {
                        margin-right: 40px;
                        margin-top: 40px;
                    }
                }
    
                @media screen and (max-width: 1200px) {
                    .image-bg, .image {
                        width: 420px;
                        height: 350px;
                    }
                    .image {
                        margin-right: 40px;
                        margin-top: 40px;
                    }
                }

                @media screen and (max-width: 990px) {
                    margin-left: 0;

                    .image-bg {  
                        margin: auto;
                        left: -60px;
                        right: 0;
                    }
                    .image-bg, .image {
                        width: 500px;
                        height: 320px;
                    }
                    .image {
                        margin-bottom: 0;
                        margin-top: 40px;
                        margin-left: 20px;
                        margin-right: 0;
                    }
                }
                @media screen and (max-width: 680px) {
                    .image-bg, .image {
                        width: 360px;
                        height: 230px;
                        border-radius: 8px;
                    }
                }
                @media screen and (max-width: 520px) {
                    .image-bg {  
                        left: -20px;
                        top: 20px;
                    }
                    .image-bg, .image {
                        width: 260px;
                        height: 180px;
                    }
                }
            }           
        }

        &:nth-child(4n+4) {
            .image-container {
                margin-right: 40px;

                .image-bg {  
                    width: 450px;
                    height: 561px;
                    bottom: 0;
                    right: 0;
                }
                .image {
                    width: 450px;
                    height: 561px;
                    margin-right: 60px;
                    margin-bottom: 70px;
                }

                @media screen and (max-width: 1550px) {
                    .image-bg, .image {
                        width: 400px;
                        height: 500px;
                    }
                    .image {
                        margin-left: 40px;
                        margin-bottom: 40px;
                    }
                }

                @media screen and (max-width: 1200px) {
                    .image-bg, .image {
                        width: 350px;
                        height: 420px;
                    }
                    .image {
                        margin-left: 40px;
                        margin-bottom: 40px;
                    }
                }

                @media screen and (max-width: 990px) {
                    margin-right: 0;

                    .image-bg {  
                        margin: auto;
                        left: -60px;
                        right: 0;
                        bottom: auto;
                    }
                    .image-bg, .image {
                        width: 500px;
                        height: 320px;
                    }
                    .image {
                        margin-bottom: 0;
                        margin-top: 40px;
                        margin-left: 20px;
                        margin-right: 0;
                    }
                }
                @media screen and (max-width: 680px) {
                    .image-bg, .image {
                        width: 360px;
                        height: 230px;
                        border-radius: 8px;
                    }
                }
                @media screen and (max-width: 520px) {
                    .image-bg {  
                        left: -20px;
                        top: 20px;
                    }
                    .image-bg, .image {
                        width: 260px;
                        height: 180px;
                    }
                }
            }
        }
    }

    .button-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: calc(48px + (112 - 48) * ((100vw - 320px) / (1920 - 320)));
    }

    .item.darkMode {
        background-color: #272727;
        padding: 60px;
        border-radius: 24px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        min-height: 325px;
        margin-bottom: 200px;

        @media screen and (max-width: 1365px) {
            padding: 40px
        }

        @media screen and (max-width: 1023px) {
            flex-direction: column-reverse;
            margin-bottom: 60px;
            padding: 0;
        }

        p {
            max-width: 50%;
            padding-right: 20px;

            @media screen and (max-width: 1365px) {
                max-width: 40%;
            }
            @media screen and (max-width: 1023px) {
                max-width: 100%;
                padding: 40px!important;
                text-align: center;
            }
            @media screen and (max-width: 520px) {
                padding: 20px!important;
            }
            
        }

        img {
            width: 445px;
            height: 445px;
            position: absolute;
            right: 60px;
            bottom: -60px;
            object-fit: cover;
            object-position: center;
            border-radius: 16px;

            @media screen and (max-width: 1365px) {
                width: 400px;
                height: 400px;
                bottom: -30px;
                right: 30px;
            }

            @media screen and (max-width: 1023px) {
                position: relative;
                right: auto;
                bottom: auto;
                width: 100%;
                height: 300px;
            }
            
        }

        &:nth-child(even) {
            justify-content: flex-end;

            img {
                position: absolute;
                left: 60px;

                @media screen and (max-width: 1365px) {
                    left: 30px;
                }

                @media screen and (max-width: 1023px) {
                    position: relative;
                    left: auto;
                    bottom: auto;
                    width: 100%;
                }
            }

            p {
                padding-right: 0;
                padding-left: 20px;
            }
        }
    }
}