.products {

    .sectionTitle-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .products-list {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 20px;
        
        @media screen and (max-width: 1320px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
        
    }
}