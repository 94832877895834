img {
    object-fit: cover;

    &.iconHighlight {
        width: 38px;
        margin-left: 8px;
    }

    &.partner {
        max-width: 260px;
        max-height: 80px;
        margin: 0 16px;
    }
}