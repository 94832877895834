.projectDetail {
    background-color: #FFFFFF;

    p {
        margin-bottom: 24px;
    }
    
    a{
        font-size: 16px;
        margin-top: 20px;
        text-decoration: none;
        color: black;
    }

    /* span:first-child {
        padding-right: 6px;
        &:after {
            content: ":";
        }
    } */
}